
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































$container-height: (
  xxs: 350px,
  xl: 650px,
);

$item-width-xxs: (
  0: 30rem,
  1: 50rem,
  2: 30rem,
  3: 45rem,
  4: 55rem,
);

$item-width-l: (
  0: 40rem,
  1: 60rem,
  2: 52rem,
  3: 43rem,
  4: 64rem,
);

$item-height: (
  0: 100%,
  1: 75%,
  2: 90%,
  3: 100%,
  4: 60%,
);

$mini-container-height: (
  xxs: 450px,
  xl: 560px,
);

$mini-item-width: (
  xxs: 240px,
  xl: 300px,
);

.flexible-case {
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  text-align: center;

  &.big {
    @include fluid(height, $container-height);
  }

  &.mini {
    @include fluid(height, $mini-container-height);
  }
}

.flexible-case__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexible-case__list {
  @extend %list-nostyle;

  position: relative;
  height: 100%;
  transition: opacity 0.2s;

  &.before-load {
    opacity: 0;
  }
}

.flexible-case__item {
  @extend %border-radius;

  position: absolute;
  top: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: content-box;
  width: 400px;
  height: 100%;
  padding: 0 math.div($spacing, 2);
  text-align: left;
  transform: translateY(-50%);

  .big & {
    @each $i, $value in $item-width-xxs {
      &:nth-child(4n + #{$i}) {
        width: $value;
      }
    }
  }

  .mini & {
    @include fluid(width, $mini-item-width);

    justify-content: flex-start;
    padding: 0 $spacing;

    &:nth-child(4n + 1) {
      justify-content: center;
    }

    &:nth-child(4n + 2) {
      justify-content: flex-end;
    }

    &:nth-child(4n + 3) {
      justify-content: center;
      padding-top: $spacing * 2;
    }
  }
}

.flexible-case__item__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;

  .mini & {
    padding-bottom: 140%;
  }

  .big & {
    display: flex;
  }

  @each $i, $value in $item-height {
    .big .flexible-case__item:nth-child(4n + #{$i}) & {
      height: $value;
    }
  }
}

::v-deep {
  .picture .picture__container {
    @extend %border-radius;

    height: 100%;
  }

  figure {
    .big & {
      display: flex;
      flex-direction: column;
    }

    .mini & {
      @include get-all-space;
    }
  }

  img {
    transition: opacity 0.2s;

    &.loaded {
      opacity: 1;
    }

    .flexible-case.big & {
      @include center-xy;

      min-width: 150%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@include mq('l') {
  .flexible-case__item {
    justify-content: center;
    padding: 0 $spacing * 1.5;

    .big & {
      @each $i, $value in $item-width-l {
        &:nth-child(4n + #{$i}) {
          width: $value;
        }
      }
    }

    .mini & {
      width: 24rem;
      padding: 0 $spacing;
    }
  }
}
